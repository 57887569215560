.SectionTitle {
  text-align: center;
  font-family: "Merriweather", serif;
}
.SectionTitle .Emoji {
  font-size: 2.8em;
}
.SectionTitle h3 {
  font-size: 1.5em;
}
.SectionSubtitle {
  font-size: 1rem;
  margin: 0.8em 2em;
  margin-left: 4em;
  color: #898786;
}

.EmojiText {
  display: flex;
  margin: 0 2em;
  color: #5b5855;
}
.EmojiText .Emoji {
  font-size: 1.2em;
}

.EmojiText p {
  font-size: 0.9em;
  margin-left: 0.5em;
  margin-top: 0.3em;
}

.ParticiperButton {
  text-align: center;
  margin: 1.5em 0;
}

.Illustration {
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 2em auto;
}

/* Caroussel */
.CarouselSection {
  margin: 30px 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.CarouselSection::-webkit-scrollbar {
  display: none; /* Safari & Chrome*/
}

.CarouselItem {
  flex: 0 0 auto;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 30px;
  width: 275px;
  height: 275px;
  box-shadow: 2px 2px 6px #a6abbd, -2px -2px 6px #fafbff;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
}
.CarouselItem:first-child {
  margin-left: 40px;
}
.CourselItem:last-child {
  margin-right: 20px;
}

.GrandesEtapes {
  display: flex;
  justify-content: center;
}
.GrandesEtapes img {
  width: 80%;
  max-width: 33em;
  margin-bottom: -3em;
}
.HeroImage {
  margin-top: 3em;
  width: 100%;
  margin-bottom: 1.5em;
  z-index: -1;
}
.HeroImage img {
  width: 100%;
}

.HighlightNote {
  margin: 2em 4em;
}
.HighlightNote span {
  display: inline-block;
  padding: 0.25em 2em;
  background-color: #e0004d;
  margin-bottom: 1em;
  border-radius: 3px;
}
.HighlightNote p {
  text-align: center;
  color: #e0004d;
  font-weight: bold;
  font-size: 1.1rem;
}

/* large tablets & laptops */
@media screen and (min-width: 768px) {
  .HeroImage {
    width: 40em;
    margin: 0 auto;
    margin-bottom: 3em;
    margin-top: 8em;
  }
  .HeroImage img {
    border-radius: 15px;
  }
  .SectionTitle {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    text-align: left;
  }
  .SectionTitle .Emoji {
    font-size: 3rem;
  }
  .SectionSubtitle {
    font-size: 1.2rem;
    margin: 0.8em 0;
    margin-left: 3.5em;
  }
  .EmojiText p {
    font-size: 1rem;
  }
  .ContactForm {
    margin: 0 auto;
    max-width: 40em;
  }
  .HighlightNote p {
    font-size: 1.3rem;
  }
}

/* large tablets & laptops */
@media screen and (min-width: 1200px) {
  .HeroImage {
    width: 50em;
    margin: 0 auto;
    margin-bottom: 4em;
    margin-top: 8em;
  }
  .Section {
    margin: 0 20em;
  }
  .Illustration {
    width: 25em;
    height: 25em;
  }
}
