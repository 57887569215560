.BlogCard {
  margin: 30px auto;
  max-width: 23rem;
  min-height: 32rem;
  width: 75%;
  background: #f3f2f0;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  border-radius: 8px;
  overflow: hidden;
}

.BlogCardImage {
  height: 22em;
  width: 100%;
  z-index: -1;
  /* border: 1px solid rgba(255, 255, 255, 0.4); */
  box-sizing: border-box;
  box-shadow: 5px 5px 10px #a6abbd, -5px -5px 10px #fafbff;
  background-repeat: no-repeat;
  background-size: cover;
}

.BlogCardDate {
  text-align: end;
  margin: 0.7rem 1.5rem;
  font-size: 0.9em;
  color: #9dabb6;
}

.BlogCardContent {
  margin: 0.7rem 1.5rem;
}
.BlogCardContent p {
  margin: 0.3rem 0;
}

.BlogCardButton {
  text-align: end;
  margin: 0.7rem 1.5rem;
  margin-top: 2rem;
}

/* Small tablets */
@media (min-width: 620px) {
  .BlogCard {
    margin: 50px auto;
  }
}

@media screen and (min-width: 768px) {
  .BlogCard {
    margin: 20px auto;
  }
}
